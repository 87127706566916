import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { getCategoryURL, getSlug } from '../utils/url'
import { Link, graphql, useStaticQuery } from 'gatsby'

const CategoryList = () => {
	const data = useStaticQuery(graphql`
		query RootCategories {
			allCategory: allSanityCategory(
				filter: { root: { eq: true } }
				sort: { fields: title, order: ASC }
			) {
				totalCount
				edges {
					node {
						slug {
							current
						}
						title
					}
				}
			}
		}
	`)

	return (
		<Layout>
			<SEO title="Categorias" />
			<h1 className="uk-heading-line">Categorias</h1>
			<ul className="uk-list">
				{data.allCategory.edges.map(({ node }) => (
					<li key={getSlug(node)}>
						<Link className="uk-link-text" to={getCategoryURL(getSlug(node))}>
							{node.title}
						</Link>
					</li>
				))}
			</ul>
		</Layout>
	)
}

export default CategoryList
